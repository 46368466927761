import { Link } from "./link"

export const Text = (props) => {
  return (
    <div class={`text-content ${props.class ? props.class : ""}`}>
      {props.heading && <h2>{props.heading}</h2>}
      {props.subheading && <h3>{props.subheading}</h3>}
      {props.text && <p>{props.text}</p>}
      {props.link && (
        <p>
          <Link {...props.link} />
        </p>
      )}
    </div>
  )
}
